import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/simple-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/lesson-plans/"
      }}>{`»`}{` Back to Lesson Plans`}</a></p>
    <p>{`This week make `}<strong parentName="p">{`at least 5 substantive posts`}</strong>{` for this lesson's threads as follows:`}</p>
    <ul>
      <li parentName="ul">{`Reply to at least 3 of the numbered questions posed in the threads below `}<strong parentName="li">{`by 5pm Tuesday.`}</strong></li>
      <li parentName="ul">{`Then keep an eye on these and the other threads and respond to other students' posts or the professor's new questions with at least 2 more replies `}<strong parentName="li">{`by 5pm Friday.`}</strong></li>
    </ul>
    <h2>{`6.1 Branding and UX`}</h2>
    <p>{`As brand strategy can influence product objectives, think of examples of products that you think said something about a company’s brand. Are these complementary to the brand or detrimental? Do you think they were intentionally incorporated or accidental?`}</p>
    <h2>{`6.2 Demographics vs. Psychographics`}</h2>
    <p>{`What is the difference between demographics and psychographics? How can they be used when strategizing?`}</p>
    <h2>{`6.3 Comparison of research methods`}</h2>
    <p>{`Compare and contrast the goals and methods used in market research, contextual inquiry and user testing. What are strengths weaknesses of each?`}</p>
    <h2>{`6.4 Ways to determine scope`}</h2>
    <p>{`Based on Garrett’s discussion of the scope plane, what are some ways we go about defining our project’s scope?`}</p>
    <h2>{`6.5 User needs`}</h2>
    <p>{`Describe one of the kinds of user needs or requirements Garrett discusses. Compare or contrast this to element from Design for Life.`}</p>
    <h2>{`6.6 Content requirements`}</h2>
    <p>{`Describe one of the qualities of content requirements Garrett discusses.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      